import React from "react"
import styled from "styled-components"
import CardsListing from "components/common/cardsListing"
import fileImg from "content/images/file.svg"
import docImg from "content/images/search.svg"
import peopleImg from "content/images/people.svg"
import ContentWrapper from "styles/contentWrapper"
import PrimaryCard from "components/common/primaryCard"
import ProfileAskingImg from "components/assets/profile-asking.png"
import ProfileUnfoldingImg from "components/assets/profile-unfolding.png";
import jobAskingImg from "components/assets/job-asking.png"

const jobParsingImg = "https://hrflow-ai.imgix.net/labs/job-parsing.png?w=350&h=350&fit=clip"
const jobRevealingImg = "https://hrflow-ai.imgix.net/labs/job-imaging.webp?w=350&h=350&fit=clip"
const jobSearching = "https://hrflow-ai.imgix.net/labs/job-searching.png?w=350&h=350&fit=clip"
const documentLinking = "https://hrflow-ai.imgix.net/labs/document-linking.png?w=350&h=350&fit=clip"
const resumeParsing = "https://hrflow-ai.imgix.net/labs/resume-parsing.png?w=350&h=350&fit=clip"
const profileSearching = "https://hrflow-ai.imgix.net/labs/profile-searching.png?w=350&h=350&fit=clip"
const textImagingImg = "https://hrflow-ai.imgix.net/labs/job-revealing.png?w=350&h=350&fit=clip"
const profileScoring = "https://hrflow-ai.imgix.net/labs/recruiter-copilot.png?w=350&h=350&fit=clip"
const jobScoring = "https://hrflow-ai.imgix.net/labs/talent-copilot.jpg?w=350&h=350&fit=clip"




const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3.75rem 0;
  padding-bottom: 12rem;
  color: ${({ theme }) => theme.colors.tertiary};

`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Demos = () => (
  <StyledSection>
    <StyledContentWrapper>
      {/* Profiles Demos */}
      <CardsListing
        icon={peopleImg}
        color="#22B5F3"
        title="Profile analysis demos"
        subtitle="Dive in to interactive demos that derive insights from profiles, resumes and CVs."
        link="/profile-analysis/"
      >
        <PrimaryCard
          image={resumeParsing}
          color="#22B5F3"
          title="Resume Parsing"
          link="/profile-analysis/parsing/"
          subtitle="Upload a resume/CV extract its semantic entities — such as names, emails, phones, locations, companies, schools, degrees, job titles, tasks, skills, interests, durations, dates and more."
        />  
        <PrimaryCard
          image={profileSearching}
          color="#22B5F3"
          title="Profile Searching"
          link="/profile-analysis/searching/"
          subtitle="Talent Pool/Marketplace Search Demo powered by HrFlow.ai Profile Searching API."
          codeUrl="https://codepen.io/HrFlow/pen/eYdOjPP"
        />
        <PrimaryCard
          image={profileScoring}
          color="#22B5F3"
          title="Recruiter Copilot"
          link="/profile-analysis/recruiter-copilot/"
          subtitle="Our AI-powered Recruiter Copilot is designed to help you or your users identify the most qualified candidates for a job opening."
        />
        <PrimaryCard
          image={ProfileAskingImg}
          color="#22B5F3"
          title="Profile Asking"
          link="/profile-analysis/asking/"
          subtitle="Ask a question based on a Profile object with HrFlow.ai Profile Asking API."
        />
        <PrimaryCard
          image={ProfileUnfoldingImg}
          color="#22B5F3"
          title="Profile Unfolding"
          link="/profile-analysis/unfolding/"
          subtitle="Predict the future experiences and educations of a profile. with HrFlow.ai Profile Unfolding API."
        />   
      </CardsListing>
      {/* Jobs Demos */}
      <CardsListing
        icon={fileImg}
        color="#2A7CF8"
        title="Job analysis demos"
        subtitle="Dive in to interactive demos that derive insights from job offers."
        link="/job-analysis/"
      >
        <PrimaryCard
          image={jobSearching}
          color="#2A7CF8"
          title="Job Searching"
          link="/job-analysis/searching/"
          subtitle="Job Listing/Marketplace Search Demo powered by HrFlow.ai Job Searching API."
          codeUrl="https://codepen.io/HrFlow/pen/xxOeGwe"
        />
        <PrimaryCard
          image={jobScoring}
          color="#2A7CF8"
          title="Talent Copilot"
          link="/job-analysis/talent-copilot/"
          subtitle="Our AI-powered Talent Copilot is designed to help your candidates, employees, or job seekers identify the most qualified job opening for them."
        />
        <PrimaryCard
          image={jobAskingImg}
          color="#2A7CF8"
          title="Job Asking"
          link="/job-analysis/asking/"
          subtitle="Ask a question based on a Job object with HrFlow.ai Job Asking API."
        />
      </CardsListing>
      {/* Text Demos */}
      <CardsListing
        icon={docImg}
        color="#9F7AEA"
        title="Text analysis demos"
        subtitle="Dive in to interactive demos that derive insights from hr texts."
        link="/text-analysis/"
      >
        <PrimaryCard
          image={jobParsingImg}
          color="#9F7AEA"
          title="Text Parsing"
          link="/text-analysis/parsing/"
          subtitle="Enter an unstructured text and extract its semantic entities — such as companies, locations, tasks, skills, interests, durations, dates and more."
        />
        <PrimaryCard
          image={jobRevealingImg}
          color="#9F7AEA"
          title="Text Tagging"
          link="/text-analysis/tagging/"
          subtitle="Predict most likely tags for a text with our library of AI algorithms."
        />
        <PrimaryCard
          image={documentLinking}
          color="#9F7AEA"
          title="Text Linking"
          link="/text-analysis/linking/"
          subtitle="Enter a word and get its Top 10 closest words whether it is a person name, school, organization, skill, interest, country and more."
        />
        <PrimaryCard
          image={textImagingImg}
          color="#9F7AEA"
          title="Text Imaging"
          link="/text-analysis/imaging/"
          subtitle="Enter a job description to generate an image that showcases the tasks required from your future employees."
        />
        {/* <PrimaryCard
          image={jobRevealingImg}
          color="#9F7AEA"
          title="Text Revealing"
          link="/text-analysis/revealing/"
          subtitle="Enter an unstructured text and predict the forgotten soft and hard skills."
        /> */}
        
        
      </CardsListing>
    </StyledContentWrapper>
  </StyledSection>
)

export default Demos
