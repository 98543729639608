import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import Context from "context/"

import ContentWrapper from "styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.solitudeDark};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .cardsWrapper {
      min-height: 19rem;
      display: flex;
      margin-top: -14rem;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
`


const Flex = ({ children }) => {
  const { isIntroDone } = useContext(Context).state
  const gControls = useAnimation()

  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        await gControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.8 },
        })
        // Animate underlining to hover state
      }
    }
    pageLoadSequence()
  }, [isIntroDone, gControls])

  return (

      <StyledSection  id="flex">
        <StyledContentWrapper>
          <motion.div
            initial={{ opacity: 0, x: 80 }}
            animate={gControls}
            className="cardsWrapper"
          >
            {children}
          </motion.div>
        </StyledContentWrapper>
      </StyledSection>
  )
}


export default Flex
