import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import { lightTheme, darkTheme } from "styles/theme"
import spins from "content/images/spins.svg"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.zodiacDark};
  background-image: url(${spins});
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 38.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding-top: 5.68rem;
    .title {
      color: white;
    }
    .subtitle {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.93rem;
      max-width: 32rem;
      margin: auto;
    }
  }
`



const Hero = ({ content }) => {
  const { frontmatter } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${
            darkMode ? darkTheme.colors.secondary : lightTheme.colors.secondary
          }`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, eControls, gControls, sControls, uControls])

  return (
    <StyledSection id="hero">
      <StyledContentWrapper>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={gControls}
          data-testid="animated-heading"
        >
          <div className="section-label">{frontmatter.label}</div>
          <h1 className="title mb-05">
            {frontmatter.titlePrefix} <br />{frontmatter.title}
          </h1>
          <h2 className="subtitle">
            {frontmatter.subtitlePrefix} <br />
            {frontmatter.subtitle}
          </h2>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}
Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
