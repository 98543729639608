import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/demos"
import Card from "components/common/card"
import Flex from "components/sections/flex"
import { seoTitleSuffix } from "config"

import fileImg from "content/images/file.svg"
import docImg from "content/images/search.svg"
import peopleImg from "content/images/people.svg"

const IndexPage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  const { useSplashScreen } = frontmatter

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Hero content={data.hero.edges} />
        <Flex>
          <Card
            color="#22B5F3"
            icon={peopleImg}
            description="Dive in to interactive demos that derive insights from profiles, resumes and CVs."
            title="Profile Analysis"
            link="/profile-analysis/"
          />
          <Card
            color="#2A7CF8"
            icon={fileImg}
            description="Dive in to interactive demos that derive insights from job offers."
            title="Job Analysis"
            link="/job-analysis/"
          />
          <Card
            color="#9F7AEA"
            icon={docImg}
            description="Dive in to interactive demos that derive insights from hr texts."
            title="Text Analysis"
            link="/text-analysis/"
          />
        </Flex>
        <Clients content={data.clients.edges} />
        <Demos />
      </Layout>
    </GlobalStateProvider>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/home/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/home/hero/" } }) {
      edges {
        node {
          frontmatter {
            label
            titlePrefix
            title
            subtitlePrefix
            subtitle
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 40, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
