import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowRight from "components/icons/arrowRight"
import StyledBtn from "styles/button"

const StyledWrapper = styled.div`
  padding: 3.75rem 0;
  padding-bottom: 6rem;
  padding-top: 0;
  position: relative;
  h2 {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 1.56rem;
    display: flex;
    align-items: center;
  }
  .subtitle {
    max-width: 36.68rem;
  }
  .icon {
    width: 2.68rem;
    height: 2.68rem;
    min-height: 2.68rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props =>props.color};
    img {
      height: 1.56rem;
      width: auto;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-bottom: 3.75rem;
  }
`
const StyledGrid = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`

const StyledButton = styled(StyledBtn)`
  position: absolute;
  top: unset;
  bottom: 2rem;
  right: 0;
  padding: 1rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  background: ${({ theme }) => theme.colors.background};
  border-radius: 0.375rem;
  svg {
    height: 0.8rem;
  }
  span {
    margin-right: 0.5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 0;
    right: 0;
    bottom: unset;
  }
`

const Demos = ({ icon, color, title, subtitle, children, link }) => (
  <StyledWrapper color={color}>
    <StyledButton to={link}>
      <span>View all</span><ArrowRight color="#6485A8" />
    </StyledButton>
    <h2>
      <span className="icon mr-1">
        <img src={icon} alt={title} />
      </span>
      <span>{title}</span>
    </h2>
    <div className="subtitle">{subtitle}</div>
    <StyledGrid>
      {children}
    </StyledGrid>
  </StyledWrapper>
)

export default Demos
